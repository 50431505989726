import React from "react"
import { CircularProgress, Stack, styled } from "@mui/material"

interface IPreloaderWrapper {
  isPending: boolean
  children: React.ReactNode
}

/**
 * Обертка-прелоадер
 *
 * @param {boolean} isPending - Статус загрузки
 * @param {React.ReactNode} children - Дочерние элементы
 *
 * @returns {React.FC<IPreloaderWrapper>} - React.FC<IPreloaderWrapper>
 */
export const PreloaderWrapper: React.FC<IPreloaderWrapper> = ({ isPending, children }) => {
  if (!isPending) return children

  return (
    <SContainer>
      <CircularProgress />
    </SContainer>
  )
}

const SContainer = styled(Stack)({
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
})

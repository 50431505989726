import React, { Fragment, ReactNode, memo, useCallback } from "react"

import { TComponentSize } from "@types"
import { SBody, SButton, SContainer, SFooter, SHeader, SModal, STypography } from "./_styles"

interface IBasicModal {
  toggleModal: () => void
  isOpenModal: boolean
  title: string
  children: ReactNode
  size?: TComponentSize
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

interface IModal extends IBasicModal {
  showFooter?: boolean
  footerChildren?: ReactNode
  headerChildren?: ReactNode
  buttonSuccessName?: string
  showSuccessButton?: boolean
  handleButtonSuccess?: () => void
  disableButtonSuccess?: boolean
  showVerticalScroll?: boolean
  buttonCloseName?: string
}

/**
 * Базовый компонент модального окна
 *
 * @param {() => void} toggleModal - Открытие/Закрытие модального окна
 * @param {boolean} isOpenModal - Открыто окно или нет
 * @param {string} title - Заголовок модального окна
 * @param {ReactNode} children - Дочерние элементы
 * @param {TComponentSize} [size=medium] - Размер модального окна
 * @param {boolean} [showFooter=true] - Показать футер модального окна
 * @param {ReactNode} footerChildren - Дочерние элементы футера модального окна
 * @param {ReactNode} headerChildren - Дочерние элементы хэдера модального окна
 * @param {string} [buttonSuccessName=Сохранить] - Название кнопки успешного события
 * @param {boolean} [showSuccessButton=true] - Показать кнопку успешного события
 * @param {() => void} handleButtonSuccess - Хендлер успешного события
 * @param {boolean} [disableButtonSuccess=false] - Отключение кнопки успешного события
 * @param {boolean} showVerticalScroll - Показать вертикальный скролл
 * @param {string} [buttonCloseName=Отмена] - Название кнопки закрытия
 * @param {Record<string, any>} otherProps - Остальные пропсы
 *
 * @returns {React.FC<IModal>} - React.FC<IModal>
 */
export const Modal: React.FC<IModal> = memo(
  ({
    toggleModal,
    isOpenModal,
    title,
    children,
    showFooter = true,
    headerChildren,
    footerChildren,
    showSuccessButton = true,
    disableButtonSuccess = false,
    buttonSuccessName = "Сохранить",
    handleButtonSuccess,
    buttonCloseName = "Отмена",
    size = "medium",
    showVerticalScroll = true,
    ...otherProps
  }) => {
    const onSuccess = useCallback((): void => {
      handleButtonSuccess && handleButtonSuccess()

      toggleModal()
    }, [])

    if (!isOpenModal) return null

    return (
      <SModal open={isOpenModal} onClose={toggleModal}>
        <SContainer size={size} {...otherProps}>
          <SHeader>
            <STypography variant="h4">{title}</STypography>
            {headerChildren}
          </SHeader>

          <SBody size={size} hideFooter={!showFooter} enableVerticalScroll={showVerticalScroll}>
            {children}
          </SBody>

          {showFooter && (
            <SFooter direction="row" spacing={2}>
              {footerChildren === undefined ? (
                <Fragment>
                  {showSuccessButton && (
                    <SButton variant="contained" disabled={disableButtonSuccess} onClick={onSuccess}>
                      {buttonSuccessName}
                    </SButton>
                  )}
                  <SButton color="secondary" variant="outlined" onClick={toggleModal}>
                    {buttonCloseName}
                  </SButton>
                </Fragment>
              ) : (
                footerChildren
              )}
            </SFooter>
          )}
        </SContainer>
      </SModal>
    )
  }
)

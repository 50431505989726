import { Box, Modal, styled, Stack, Paper, Typography, Button } from "@mui/material"

import { TComponentSize } from "@types"

interface ISBodyExcludedProps {
  size: TComponentSize
  hideFooter: boolean
  enableVerticalScroll: boolean
}
type TOverflowY = "visible" | "hidden" | "clip" | "scroll" | "auto"

const SBodyExcludedProps: PropertyKey[] = ["size", "hideFooter", "enableVerticalScroll"]

const SModal = styled(Modal)({
  backdropFilter: "blur(2px)",
  overflow: "hidden",
})

const SContainer = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "size",
})<{ size: TComponentSize }>(({ theme, size }) => {
  const initialStyle = {
    borderRadius: 0,
    backgroundColor: theme.palette.secondary.A700,
    transform: "translate(-50%, -50%)",
    outline: "none",
    display: "flex",
    position: "absolute" as const,
    left: "50%",
    top: "50%",
  }

  if (size === "small") {
    return {
      width: "450px",
      minHeight: "250px",
      flexDirection: "column",
      ...initialStyle,
    }
  } else if (size === "medium") {
    return {
      width: "482px",
      maxWidth: "590px",
      maxHeight: "calc(100vh - 120px)",
      flexDirection: "column",
      ...initialStyle,
    }
  } else if (size === "large") {
    return {
      width: "90%",
      maxWidth: "1400px",
      maxHeight: "calc(100vh - 120px)",
      flexDirection: "column",
      ...initialStyle,
    }
  }
})

const SHeader = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.secondary.A100}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px 16px",
}))

const STypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  userSelect: "none",
  textTransform: "uppercase",
}))

const SBody = styled(Box, {
  shouldForwardProp: (prop) => !SBodyExcludedProps.includes(prop),
})<ISBodyExcludedProps>(({ size, hideFooter, enableVerticalScroll }) => {
  const initialStyle = {
    overflow: "hidden",
    padding: hideFooter ? "0px" : "16px",
  }

  const overflowY = ((): TOverflowY => {
    if (enableVerticalScroll) return "scroll"
    return hideFooter ? "hidden" : "auto"
  })()

  if (size === "small") {
    return {
      maxHeight: "400px",
      overflowY,
      ...initialStyle,
    }
  }

  if (size === "medium" || size === "large") {
    return {
      maxHeight: hideFooter ? "800px" : "730px",
      overflowY,
      ...initialStyle,
    }
  }
})

const SFooter = styled(Stack)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "16px",
  marginTop: "auto",
}))

const SButton = styled(Button)({
  height: "30px",
})

export { SContainer, SHeader, STypography, SBody, SFooter, SModal, SButton }

import React, { useState } from "react"
import { IconButton } from "@mui/material"
import AppsIcon from "@mui/icons-material/Apps"

import { theme } from "@constants"

export const WidgetsSelect: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <IconButton
        id="widget-button"
        children={<AppsIcon htmlColor={theme.palette.secondary.main} />}
        onClick={handleClick}
        aria-controls={open ? "widget-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disabled
      />
    </>
  )
}

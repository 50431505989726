import React, { useCallback, useEffect, useState } from "react"
import { styled, Typography } from "@mui/material"

import { useLocalizationContext } from "@context"

interface IResponsiveWidthMessage {
  children: React.ReactNode
}

const MIN_SCREEN_SIZE = 1280

/**
 * Заглушка при недостаточной ширине вьюпорта
 *
 * @param {React.ReactNode} children - Дочерние элементы
 *
 * @returns {React.FC<IResponsiveWidthMessage>} - React.FC<IResponsiveWidthMessage>
 */
export const ResponsiveWidthMessage: React.FC<IResponsiveWidthMessage> = ({ children }) => {
  const { l } = useLocalizationContext()

  const [isMobile, setIsMobile] = useState(window.innerWidth < MIN_SCREEN_SIZE)

  const onOrientationAndWidthChange = useCallback((): void => {
    setIsMobile(window.innerWidth < MIN_SCREEN_SIZE)
  }, [])

  useEffect(() => {
    window.addEventListener("resize", onOrientationAndWidthChange)

    return () => {
      window.removeEventListener("resize", onOrientationAndWidthChange)
    }
  }, [])

  if (isMobile) {
    return <STypography variant="h2" children={l.ui.lowResMessage} />
  }

  return children
}

const STypography = styled(Typography)({
  textAlign: "center",
  marginTop: "30%",
})

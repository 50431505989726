import React from "react"
import { IconButton, Stack, styled } from "@mui/material"
import NotificationsIcon from "@mui/icons-material/Notifications"

import { theme } from "@constants"
import { LogoutButton, Logo, Navigation, TimerComp, WidgetsSelect } from "./fragments"

/**
 * Общий хедер для всех страниц
 *
 * @returns {React.FC} - React.FC
 */
export const Header: React.FC = () => {
  return (
    <SHeaderFluid>
      <SHeaderContainer>
        <Stack direction="row" gap="40px">
          <Logo />
          <Navigation />
        </Stack>

        <SMainWrapper>
          <TimerComp />

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <IconButton disabled>
              <NotificationsIcon htmlColor={theme.palette.primary.main} />
            </IconButton>
            <WidgetsSelect />
            <LogoutButton />
          </Stack>
        </SMainWrapper>
      </SHeaderContainer>
    </SHeaderFluid>
  )
}

const SHeaderFluid = styled("div")(({ theme }) => ({
  background: theme.palette.secondary.A700,
  width: "100%",
}))

const SHeaderContainer = styled(Stack)(({ theme }) => ({
  maxWidth: "1920px",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
  padding: "0 40px",
  margin: "0 auto",
}))

const SMainWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
}))

import React, { useMemo } from "react"
import { styled } from "@mui/material"
import { NavLink } from "react-router-dom"

import { useLocalizationContext } from "@context"
import { getItemSidebarNavigation } from "./_constants"

export const Navigation: React.FC = () => {
  const { l } = useLocalizationContext()

  const items = useMemo(
    () =>
      getItemSidebarNavigation(l).map(({ path, label }, index) => {
        return <SItem to={path} children={label} key={path} disabled={index > 1} />
      }),
    []
  )

  return <SNavigation children={items} />
}

const SNavigation = styled("div")(({ theme }) => ({
  "display": "flex",

  ".active": {
    borderBottomColor: theme.palette.primary.light,
    color: theme.palette.secondary.main,
    pointerEvents: "auto",
  },
}))

const SItem = styled(NavLink, { shouldForwardProp: (prop) => prop !== "disabled" })<{ disabled: boolean }>(
  ({ theme, disabled }) => ({
    "borderBottom": `2px solid transparent`,
    "height": "100%",
    "display": "flex",
    "alignItems": "center",
    "color": theme.palette.secondary.A300,
    "marginRight": "16px",
    "pointerEvents": disabled ? "none" : "auto",
    "padding": "11px 0",

    "&:hover": {
      borderBottomColor: theme.palette.primary.light,
      color: theme.palette.secondary.main,
    },
  })
)

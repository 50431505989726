import React, { useCallback } from "react"
import { IconButton } from "@mui/material"
import LogoutIcon from "@mui/icons-material/Logout"
import { useNavigate } from "react-router-dom"

import { CookieJar } from "@utils"
import { ROUTE_PATHS } from "@routes"
import { theme } from "@constants"

export const LogoutButton: React.FC = () => {
  const navigate = useNavigate()

  const onLogout = useCallback(() => {
    CookieJar.deleteCookie("isAuthorized")

    navigate(ROUTE_PATHS.HOME)
  }, [])

  return (
    <IconButton onClick={onLogout}>
      <LogoutIcon htmlColor={theme.palette.secondary.main} />
    </IconButton>
  )
}

import { ILocale } from "@types"
import { ROUTE_PATHS } from "@routes"

type TItemSidebarNavigation = {
  path: string
  label: string
}[]

export const getItemSidebarNavigation = (l: ILocale): TItemSidebarNavigation => [
  {
    path: ROUTE_PATHS.DASHBOARD,
    label: l.header.navigation.dashboard,
  },
  {
    path: ROUTE_PATHS.MARKETPLACE,
    label: l.header.navigation.marketplace,
  },
  {
    path: ROUTE_PATHS.MAP,
    label: l.header.navigation.map,
  },
  {
    path: ROUTE_PATHS.FORECAST,
    label: l.header.navigation.forecast,
  },
  {
    path: ROUTE_PATHS.ANALYTICS,
    label: l.header.navigation.analytics,
  },
  {
    path: ROUTE_PATHS.SENSORS,
    label: l.header.navigation.sensors,
  },
  {
    path: ROUTE_PATHS.JOURNAL,
    label: l.header.navigation.journal,
  },
]

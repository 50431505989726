import React from "react"
import { NavLink } from "react-router-dom"
import { styled } from "@mui/material"

import { ROUTE_PATHS } from "@routes"
import { BCLogo } from "@components"

export const Logo: React.FC = () => {
  return <SNavLink to={ROUTE_PATHS.DASHBOARD} children={<BCLogo width={190} height={40} />} />
}

const SNavLink = styled(NavLink)({
  display: "flex",
  alignItems: "center",
})
